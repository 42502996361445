* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  background: #131215;
  //   background: #fff;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
}

body {
  color: #fefefe;
  //   color: #131215;
}

a {
  color: inherit;
  text-decoration: none;
}

/* #181A26 */
/* #0F101B */
