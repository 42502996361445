.wrapper {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.topNav {
  .navWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #171717;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.backIcon {
  margin-left: 1.8rem;
}

.heading {
  margin-right: 3.2rem;
}
.allTransactionContainer {
  margin-top: 7rem;
}

.transactionContainer {
  padding: 2rem;
}

.approved {
  p {
    color: rgb(60, 186, 60);
    font-size: 13px;
    font-weight: 600;
  }
}

.rejected {
  p {
    color: rgb(220, 20, 60);
    font-size: 13px;
    font-weight: 600;
  }
}

.pending {
  p {
    color: rgb(220, 20, 60);
    font-size: 13px;
    font-weight: 600;
  }
}

.noTransaction {
  color: #f4f4f4;
}

.loadingContainer {
  padding: 2rem;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderType {
  display: flex;
  gap: 2rem;

  h1 {
    font-size: 16px;
    letter-spacing: 1px;

    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.bottom {
  h3 {
    margin-top: 0.5rem;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    // color: #80845fff;
    color: #c1c68e;
  }
  h2 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #c1c68e;
  }
  p {
    margin-top: 0.7rem;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #c1c68e;
    margin-bottom: 21px;
  }
  .commentBox {
    border-radius: 15px;
    border-width: 1px;
  }
}

.topContent {
  h1 {
    font-size: 24px;
    letter-spacing: 1.2px;
  }
}

.actionArea {
  margin-top: 1rem;
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;

  .actionSuccess {
    font-weight: 600;
    background-color: green;
    height: 42px;
  }
  .action {
    font-weight: 600;
    height: 42px;
  }
}

.sell {
  h1 {
    color: rgb(220, 20, 60);
    font-size: 22px;
    letter-spacing: 1.2px;
  }
}

.buy {
  h1 {
    color: rgb(46, 156, 46);
    font-size: 22px;
    letter-spacing: 1.2px;
  }
}

.txn {
  font-size: 13px;
  font-weight: 500;
  list-style: 0.3px;
}

.transactionContainer {
  padding: 1rem;
}

.customCard {
  background: #171717;
  color: white;
  border: transparent;
  box-shadow: 0px 0px 0px rgb(36, 36, 36);
  -webkit-transition: box-shadow 0.6s ease-out;
  transition: box-shadow 0.6s ease-out;

  &:hover {
    box-shadow: 1px 8px 20px rgb(36, 36, 36);
    -webkit-transition: box-shadow 0.6s ease-in;
    transition: box-shadow 0.6s ease-in;
  }
}

.commentsContainer {
  margin-top: 0.1rem;
  font-size: 14px;
}

.comment {
  font-weight: bold;
  color: #007fff;
}
