.main {
  background-image: linear-gradient(to top left, #0f101b, #181a26);
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  font-size: 1.6rem;
}

.lightEffect {
  position: absolute;
  top: -100%;
  left: -100%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  transform: rotate(45deg);
  pointer-events: none; /* To ensure the spotlight doesn't interfere with content interaction */
}

.showcaseImage {
  margin: 3rem auto 3rem auto;
  display: block;
}

.brandTitle {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.showcaseArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  gap: 2rem;
  padding: 10rem 0;
}

.brandTagline {
  color: #d4d4d4;
  font-weight: 400;
  text-align: center;
}
