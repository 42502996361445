.wrapper {
  font-size: 1.6rem;
  background-color: #131215;
  min-height: inherit;
  position: relative;
  overflow: hidden;
}

.lightEffect {
  position: absolute;
  top: -100%;
  left: -100%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba($color: #232027, $alpha: 0.2) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  transform: rotate(45deg);
  pointer-events: none;
}

.productWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1rem 5rem 1rem;
}

.card {
  padding: 1rem;
  background: #1f1d22;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
  color: #e8e8ea;
  box-shadow: 0 5px 10px #1b1a1f;
  gap: 1rem;

  .cardContent {
    display: flex;
    gap: 1rem;
  }

  .cardInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .productTitle,
    .sellingPrice {
      font-weight: bold;
    }

    .productTag {
      display: flex;
      gap: 0.5rem;
      color: #b3bcbc;
    }
  }
}

.cardPrice {
  display: flex;
  gap: 1rem;
  color: rgb(3, 147, 3);
  font-weight: 600;
  font-size: 1.4rem;
}

.totalReturn {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: right;
  width: 100%;
}

.negative {
  color: #ff7875;
}

.positive {
  color: rgb(46, 156, 46);
}

.cardPriceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.actionArea {
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;

  .actionSuccess {
    background-color: green;
  }
}

.loader {
  width: 35px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #d4d4d4 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
  33% {
    background-size:
      calc(100% / 3) 0%,
      calc(100% / 3) 100%,
      calc(100% / 3) 100%;
  }
  50% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 0%,
      calc(100% / 3) 100%;
  }
  66% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 100%,
      calc(100% / 3) 0%;
  }
}

.productDisplay {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
}

.productItems {
  background-color: #1b1b1b;
  padding: 2rem;
  flex: 1;
  min-width: 40%;

  h3 {
    color: #7b8083;
    font-size: 1.4rem;
    font-weight: 500;
  }

  h2 {
    margin-top: 0.5rem;
    font-size: 2rem;
    font-weight: 500;
  }
  .productItemPercentage {
    margin-top: 0.6rem;
    display: flex;

    gap: 0.6rem;
    .caret {
      padding-top: 0.2rem;
    }
    p {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}

.profit {
  color: rgb(3, 147, 3);
}

.loss {
  color: #ff4d4f;
}
