.pnlWrapper {
  margin: 2rem 0.2rem;
  hr {
    margin: 0 1rem;
    height: 0.1px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: #eee;
    opacity: 0.2;
  }
}

.pnlComponents {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1.5rem;
}

.productPnl {
  margin-top: 1rem;
  h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.2rem;
  }
  .pnlInfo {
    display: flex;
    white-space: nowrap;
  }

  .positive {
    color: green;
  }

  .negative {
    color: #ff7875;
  }

  p {
    font-weight: bold;
    margin-top: 0.3rem;
    font-size: 1.2rem;
  }
}

.wrapper {
  padding: 2rem 1rem;
  padding-bottom: 6rem;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > h2 {
    padding-bottom: 0.5rem;
  }
}

.card {
  padding: 1rem;
  background: #1f1d22;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
  color: #e8e8ea;
  box-shadow: 0 5px 10px #1b1a1f;
  gap: 1rem;

  .cardContent {
    display: flex;
    gap: 1rem;
  }

  .cardInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;

    .productTitle,
    .sellingPrice {
      font-weight: bold;
    }

    .productTag {
      display: flex;
      gap: 0.5rem;
      color: #b3bcbc;
    }
  }
}

.description {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.cardPrice {
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
}

.cardPriceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}

.actionArea {
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;

  .actionSuccess {
    background-color: green;
  }
}

.userCard {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem;

  .info {
    font-size: 1.4rem;
  }
}

.noTransaction {
  color: #f4f4f4;
}

.amountContainer {
  display: flex;
  align-items: center;
}

.swapIcon {
  margin-left: 10px;
  cursor: pointer;
  color: #228b22;
}

.loader {
  width: 35px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #d4d4d4 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
  33% {
    background-size:
      calc(100% / 3) 0%,
      calc(100% / 3) 100%,
      calc(100% / 3) 100%;
  }
  50% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 0%,
      calc(100% / 3) 100%;
  }
  66% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 100%,
      calc(100% / 3) 0%;
  }
}

.overallPnl {
  background-color: #1b1b1b;
  padding: 1.2rem 1.2rem;
  margin: 2rem 0;
  border-radius: 0.5rem;

  p {
    font-size: 1.4rem;
    font-weight: 430;
    color: #7b8083;
  }
  h3 {
    margin-top: 0.25rem;
    font-size: 1.8rem;
    font-weight: 550;
    color: rgb(3, 147, 3);
  }
  h4 {
    margin-top: 0.1rem;
    font-size: 1.3rem;
    font-weight: 550;
    color: rgb(3, 147, 3);
  }
}
