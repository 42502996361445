.wrapper {
  padding: 2rem;
  padding-bottom: 10rem;
  font-size: 1.6rem;
}

.title {
  font-size: 3rem;
}

.desc {
  font-size: 1.6rem;
}

.paymentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentTitle {
  font-size: 2rem;
}

.walletAddress {
  display: flex;
  width: 100%;
  input {
    padding: 1rem;
    background: #d3d3d3;
    border: none;
    outline: none;
    width: 100%;
    flex: 1;
  }

  button {
    padding: 10px;
    background-color: #28a745;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
}

.qr {
  max-width: 400px;
  width: 100%;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.paymentInstructions {
  h1 {
    font-size: 22px;
    font-weight: 400;
  }
  p {
    margin-top: 10px;
    font-size: 15px;
  }
}

.sellInstructions {
  h1 {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 400;
  }
  h4 {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 400;
  }
}

.detail {
  p {
    width: 50%;
  }
}

.submit {
  margin-top: 25px;
  width: 100%;
  background-color: green;
  padding: 2rem 0;
  font-size: 1.6rem;
}

input {
  font-size: 1.6rem;
  padding: 0.5rem;
  outline: none;

  &[type='number'] {
    width: 10rem;
  }

  &[type='file'] {
    width: 100%;
    padding: 1rem 0;

    text-align: center;
    &::-webkit-file-upload-button {
      width: 100%;
      display: flex;
      background: rgb(149, 111, 253);
      color: #fff;
      border: none;
      border-radius: 0.5rem;
      padding: 1rem 2rem;
      cursor: pointer;
    }
  }
}

.errorText {
  color: rgb(177, 48, 48);
  font-size: 1.2rem;
}

.previewContainer {
  margin-top: 10px;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 4px;
  display: inline-block;
}

.previewImage {
  max-width: 150px;
  max-height: 150px;
}
