.house {
  width: 2.8rem;
}

.houseItemStyles {
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
