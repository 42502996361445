.wrapper {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.topNav {
  .navWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #171717;
    z-index: 1000;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.approvedTopNav {
  margin-top: 7.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  h1 {
    font-weight: 500;
  }
}

.backIcon {
  margin-left: 1.8rem;
}

.hamburgerIcon {
  margin-right: 1rem;
}

.heading {
  margin-left: 1.2rem;
}

.updatePrice {
  padding-top: 4.5rem;
  margin: 2rem 2rem;
  Button {
    font-size: 1.5rem;
    font-weight: 600;
    background-color: rgb(46, 156, 46);
  }
  .action {
    height: 42px;
  }
}

.txn {
  font-size: 1.3rem;
  font-weight: 500;
  list-style: 0.3px;
}

.transactionContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customCard {
  background: #171717;
  color: white;
  border: transparent;
  // box-shadow: 0px 0px 0px rgb(36, 36, 36);
  -webkit-transition: box-shadow 0.6s ease-out;
  transition: box-shadow 0.6s ease-out;

  &:hover {
    // box-shadow: 2px 8px 20px rgb(37, 37, 37);
    -webkit-transition: box-shadow 0.6s ease-in;
    transition: box-shadow 0.6s ease-in;
  }

  .ant-card-body {
    padding: 1rem;
  }
}

.approved {
  p {
    color: rgb(60, 186, 60);
    font-size: 1.3rem;
    font-weight: 600;
  }
}

.rejected {
  p {
    color: rgb(220, 20, 60);
    font-size: 1.3rem;
    font-weight: 600;
  }
}

.pending {
  p {
    color: rgb(220, 20, 60);
    font-size: 1.3rem;
    font-weight: 600;
  }
}

.noTransaction {
  color: #f4f4f4;
}

.belowContent {
  margin-left: 2.6rem;
}

.transactionId {
  font-weight: bold;
  color: #1877f2;
}

.commentsContainer {
  margin-top: 0.4rem;
  font-size: 1.4rem;
}

.comment {
  font-weight: bold;
  color: #007fff;
}

.loadingContainer {
  padding: 2rem;
}

.currentPrice {
  margin-top: 1rem;
  label {
    margin-right: 0.3rem;
  }
}

.commentValid {
  color: #ff474c;
  font-size: 1.3rem;
  margin-left: 0.2rem;
}

.updatePrice {
  margin-top: 0.5rem;
  label {
    margin-right: 0.4rem;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderType {
  display: flex;
  gap: 2rem;

  h1 {
    font-size: 1.6rem;
    letter-spacing: 0.1rem;

    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.bottom {
  h3 {
    margin-top: 0.5rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;
    // color: #80845fff;
    color: #c1c68e;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: #c1c68e;
  }
  .commentBox {
    border-radius: 1.5rem;
    border-width: 0.1rem;
    padding: 1rem 0;
  }
}

.actionArea {
  // margin-top: 1rem;
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;

  .actionSuccess {
    font-weight: 600;
    background-color: green;
    height: 42px;
  }
  .action {
    font-weight: 600;
    height: 42px;
  }
}

.sell {
  h1 {
    color: rgb(220, 20, 60);
    font-size: 2.2rem;
    letter-spacing: 1.2px;
  }
}

.buy {
  h1 {
    color: rgb(46, 156, 46);
    font-size: 2.2rem;
    letter-spacing: 1.2px;
  }
}

.priceError {
  color: #ff474c;
  font-size: 1.3rem;
  margin-left: 0.125rem;
}

.topHeading {
  display: flex;
  align-items: center;
  gap: 1rem;
  h1 {
    font-size: 1.3rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.3rem;
    color: #c1c68e;
  }
}

.pnlWrapper {
  margin: 2rem 0.2rem;
  hr {
    margin: 0 1rem;
    height: 0.1px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: #eee;
    opacity: 0.2;
  }
}

.pnlComponents {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1.5rem;
}

.productPnl {
  // margin-top: 1rem;
  h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.2rem;
  }

  .pnlInfo {
    display: flex;
    white-space: nowrap;
  }

  .positive {
    color: green;
  }

  .negative {
    color: #ff7875;
  }

  p {
    font-weight: bold;
    margin-top: 0.05rem;
    font-size: 1.2rem;
    color: green;
  }
}

.card {
  margin-top: 1rem;
  padding: 1rem;
  background: #1f1d22;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
  color: #e8e8ea;
  box-shadow: 0 5px 10px #1b1a1f;
  gap: 1rem;

  .cardContent {
    display: flex;
    gap: 1rem;
  }

  .cardInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;

    .productTitle,
    .sellingPrice {
      font-weight: bold;
    }

    .productTag {
      display: flex;
      gap: 0.5rem;
      color: #b3bcbc;
    }
  }
}

.description {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.cardPrice {
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
}

.cardPriceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}
