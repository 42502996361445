.portfolioSummary {
  padding: 2rem 1rem;
  background-color: #212121;
  padding-bottom: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  box-shadow: 0 2px 10px #222121;
  font-size: 1.3rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  .head {
    font-size: 1.5rem;
  }

  h3 {
    margin-top: 0.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
  }

  .curencyToggle {
    display: inline-flex;
    align-items: center;
  }

  .swapIcon {
    font-size: 1.9rem;
    margin-right: 8px;
  }
}

.portfolioHead {
  margin-top: 0.2rem;
}

.loader {
  width: 35px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #d4d4d4 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
  33% {
    background-size:
      calc(100% / 3) 0%,
      calc(100% / 3) 100%,
      calc(100% / 3) 100%;
  }
  50% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 0%,
      calc(100% / 3) 100%;
  }
  66% {
    background-size:
      calc(100% / 3) 100%,
      calc(100% / 3) 100%,
      calc(100% / 3) 0%;
  }
}

.userCard {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  text-align: right;
  margin-right: 0.3rem;

  .top {
    margin-right: 0.8rem;
  }

  .bottom {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    gap: 1rem;
  }
}

.email {
  font-weight: 500;
}
